<template>
    <div style="height: 100%">
        <div v-for="(item, i) in breadcrumbsItems" :key="i" class="router-frame">
            <div class="router-text font-16"  :id="!!item.id ? item.id:''" v-if="item.disabled == true">{{item.text}}</div>
            <div class="router-text font-16" v-if="item.disabled == false">
                <a class="font-16" href="javascript:void(0)" @click="$router.push(item.to)">{{item.text}}</a>
            </div>
            <div class="router-icon mx-2" v-if="i != breadcrumbsItems.length - 1">
                <i class="dx-icon dx-icon-chevronright"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["breadcrumbsItems"],
    computed: {
        ItemsWithExact: {
            get() {
                return this.breadcrumbsItems.map(item => {
                    return Object.assign({}, item, { exact: true });
                });
            }
        }
    },
    data() {
        return {};
    }
};
</script>

<style scoped>
.router-frame {
    float: left;
    width: max-content;
    height: 100%;
}
.router-text {
    float: left;
    width: max-content;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: #000 !important
}
.router-text a {
    text-decoration: none;
    color: #000 !important
}
.router-icon {
    float: left;
    width: max-content;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.router-icon i.dx-icon{
    font-size: 20px;
}
</style>